;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"b0676f58da254d5a1447c47b72f9284d0f85b70c"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/fem/b0676f58da254d5a1447c47b72f9284d0f85b70c";import * as Sentry from '@sentry/nextjs'
import {getEnvironment} from 'sentry.utils'
import {env} from '~/config/env.mjs'
import {isProd} from '~/config/nodeEnv'

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,
  enabled: isProd,
  environment: getEnvironment(),
})
